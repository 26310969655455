import React from 'react';
import {Banner, Feature} from '../components';
import SubscribeContainer from './subscribe';

function BannerContainer() {
	return (
		<Banner>
			<Banner.Title>Không giới hạn phim, chương trình truyền hình và hơn thế nữa.</Banner.Title>
			<Banner.SubTitle>Xem ở bất cứ đâu. Hủy bất cứ lúc nào.</Banner.SubTitle>
			<SubscribeContainer />
		</Banner>
	);
}

export default BannerContainer;
