import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';
import { Spinner as LoadingSpinner } from '../../loading/styles/loading';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  background: url('/images/misc/Home_Banner.jpg');
  background-size: cover;
  background-position: 50% 50%;
  //border-bottom: 0.5rem solid #222;
  //background-image : url('/images/misc/Home_Banner.jpg');
`;
export const Inner = styled.div`
	background: rgba(12, 44, 92, .75);
	max-width: 20rem;
	margin: auto;
	padding: 3.5rem 4.5rem 5.5rem;
	color: #fff;
	border-radius: 0.25rem;
`;
export const Title = styled.h1`
	font-size: 2rem;
	margin: 0 0 1.5rem;
`;

export const TitleProfile = styled.h3`
	font-size: 1.5rem;
	margin: 0 0 1.5rem;
`;
export const FormGroup = styled.form`
	display: flex;
	flex-flow: row wrap;
`;
export const Input = styled.input`
	width: 100%;
	//background: #333;
	//color: #fff;
	padding: 1rem;
	margin: 0.5rem 0;
	border-radius: 0.25rem;
	outline: none;
	border: none;
	border-bottom: 2px solid #333;
	transition: border-bottom 0.25s ease-out;

	&.has-error {
		border-bottom: 2px solid #e87c03;
	}
`;
export const Button = styled.button`
  width: 100%;
  background-color: #f58020;
  color: #fff;
  font-weight: 800;
  font-size: 1rem;
  margin-top: 1.5rem;
  padding: 1rem 0.25rem;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in;

  &:hover {
    background-color: #f5710d;
  }

  &:disabled {
    background-color: #f26b38;
    color: #ffffff;
    cursor: default;
  }
`;
export const ButtonPackage = styled.button`
  width: 100%;
  background: linear-gradient(45deg, #753bbd 6%, #c724b1 53%);
  color: #fff;
  font-weight: 800;
  font-size: 1rem;
  margin-top: 1.5rem;
  padding: 1rem 0.25rem;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in;

  &:hover {
    background: linear-gradient(45deg, #d92cc1 6%, #824bc7 53%);
  }

  &:disabled {
    background-color: #f26b38;
    color: #ffffff;
    cursor: default;
  }
`;
export const Spinner = styled(LoadingSpinner)`
	background: none;
	width: 2rem;
	height: 0;
	margin-top: -0.15rem;
	:after {
		width: 1.5rem;
		height: 1.5rem;
    filter: contrast(0) brightness(1.75);
		margin-top: 0;
		margin-left: 0;
	}
`;
export const Text = styled.p`
	color: #737373;
	font-size: 1.1rem;
`;

export const TextCheckBoxStyle = styled.p`
  color: #737373;
  width: 100%;
  font-size: 1.1rem;
`;

export const TextSmall = styled.p`
	font-size: 0.8rem;
	color: #8c8c8c;
`;
export const Link = styled(RouterLink)`
	color: #fff;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;
export const Error = styled.p`
	margin: 0 0.25rem 0.5rem;
	font-size: 0.85rem;
	color: #e87c03;

	&.boxed {
		background-color: #e87c03;
		color: #fff;
		border-radius: 0.25rem;
		padding: 0.5rem;
	}
`;

export const InnerCus = styled.div`
  background: rgba(12, 44, 92, .75);
  max-width: 60rem;
  margin: auto;
  padding: 3.5rem 4.5rem 5.5rem;
  color: #fff;
  border-radius: 0.25rem;
`;

export const InnerPackages = styled.div`
  background: rgba(12, 44, 92, .75);
  max-width: 30rem;
  margin: auto;
  padding: 2.5rem 3.5rem 4.5rem;
  color: #fff;
  border-radius: 0.25rem;
`;

export const TextCus = styled.p`
  color: #fff;
  font-size: 1.1rem;
  @media (max-width: 1000px) {
    font-size: 1rem;
  }
  @media (max-width: 412px) {
    font-size: 0.8rem;
  }
`;

export const InputCus = styled.input`
  width: 20%;
`;

export const ContainerCus = styled.div`
  display: flex;
  height: 100vh;
  background: url('/images/misc/Home_Banner.jpg');
  background-size: cover;
  background-position: 50% 50%;
  @media (max-width: 1000px) {
    height: 140vh;
  }
  @media (max-width: 412px) {
    height: 220vh;
  }
`;

export const ContainerPackages = styled.div`
  display: flex;
  height: 100vh;
  padding: 10px;
  background-size: cover;
  background-position: 50% 50%;
  @media (max-width: 1000px) {
    height: 140vh;
  }
  @media (max-width: 412px) {
    height: 220vh;
  }
`;

export const TitlePackages = styled.h1`
	font-size: 1.5rem;
	margin: 1rem 1rem 1.5rem;
`;


export const ContainerPayment = styled.div`
  display: flex;
  height: 100vh;
  padding: 10px;
  background: url('/images/misc/Home_Banner.jpg');
  background-size: cover;
  background-position: 50% 50%;
  @media (max-width: 1000px) {
    height: 140vh;
  }
  @media (max-width: 412px) {
    height: 220vh;
  }
`;

export const InnerPayment = styled.div`
  background: rgba(12, 44, 92, .75);
  max-width: 60rem;
  margin: auto;
  padding: 3.5rem 4.5rem 5.5rem;
  color: #fff;
  border-radius: 0.25rem;
`;
