const API_KEY = process.env.REACT_APP_API_KEY;

export type SectionType = { title: string; endpoint: string; size?: string };
type Sections = {
	[key: string]: {
		sections: Array<SectionType>;
		helpers: { [key: string]: string };
	};
};

export const SECTIONS: Sections = {
	movies: {
		sections: [
			{
				title: 'Thriller',
				endpoint: `/movies/Thriller`
			},
			{
				title: 'Politist',
				endpoint: `/movies/Politist`
			},
			{
				title: 'Banner',
				endpoint: `/movies/Banner`
			}
		],
		helpers: {
			searchMovie: `/search/movie?api_key=${API_KEY}&query={{query}}`,
			fetchMovieGenres: `genre/movie/list?api_key=${API_KEY}`,
			// fetchMovieVideos: `/movie/{{movie_id}}/videos?api_key=${API_KEY}`,
			fetchMovieVideos: `/movie/{{movie_id}}/videos?api_key=${API_KEY}`,
			fetchMovieDetails: `/movie/{{movie_id}}?api_key=${API_KEY}`,
			fetchMovieRecommendations: `/movie/{{movie_id}}/recommendations?api_key=${API_KEY}`,
			fetchMovieCredits: `/movie/{{movie_id}}/credits?api_key=${API_KEY}`,
			fetchMovieVideosGalaxyLinks: `/movie/{{movie_id}}`,
			fetchMovieDetailsGalaxyLinks: `/movie/{{movie_id}}`,
		}
	},
	television: {
		sections: [
			{title: 'Popular on Galaxy Links', endpoint: `/tv/popular?api_key=${API_KEY}`},
			{
				title: 'Television',
				endpoint: `/movies/Television`
			}
		],
		helpers: {

		}
	},
	series: {
		sections: [
			{ title: 'Popular on Galaxy Links', endpoint: `/tv/popular?api_key=${API_KEY}` }
		],
		helpers: {
			searchTV: `/search/tv?api_key=${API_KEY}&query={{query}}`,
			fetchTVGenres: `genre/tv/list?api_key=${API_KEY}`,
			fetchTVVideos: `/movie/{{tv_id}}`,
			fetchTVDetails: `/movie/{{tv_id}}`,
			fetchTVAggregateCredits: `/tv/{{tv_id}}/aggregate_credits?api_key=${API_KEY}`,
			fetchTVRecommendations: `/tv/{{tv_id}}/recommendations?api_key=${API_KEY}`,
			fetchTVSeason: `/tv/{{tv_id}}/season/{{season_number}}?api_key=${API_KEY}`
		}
	}
};
