import { Home, Browse, Signin, Signup, Help, Policy, Regulations, Promotion } from '../pages';
import Page404 from "../pages/error/404";
import Forgot from "../pages/forgot";
import ChangePass from "../pages/changePass";
import ProfileUser from "../pages/profile";
import Payment from "../pages/payment/payment";

type Routes = {
	[key: string]: {
		path: string;
		page: JSX.Element;
		isProtected: boolean;
	};
};
//policy
//regulations
//promotion Forgot password
export const ROUTES: Routes = {
	HOME: { path: '/', page: <Home />, isProtected: false },
	BROWSE: { path: '/browse', page: <Browse />, isProtected: true },
	PAYMENT: { path: '/payment', page: <Payment />, isProtected: true },
	PAGE_404: { path: '/page404', page: <Page404 />, isProtected: true },
	PAGE_CHANGE_PASS: { path: '/change-password', page: <ChangePass />, isProtected: true },
	PAGE_PROFILE: { path: '/profile', page: <ProfileUser />, isProtected: true },
	SIGNUP: { path: '/signup', page: <Signup />, isProtected: false },
	SIGNIN: { path: '/signin', page: <Signin />, isProtected: false },
	FORGOT_PASSWORD: { path: '/forgot-password', page: <Forgot />, isProtected: false },
	HELP: { path: '/help', page: <Help />, isProtected: false },
	POLICY: { path: '/quy-che-su-dung-dich-vu', page: <Policy />, isProtected: false },
	REGULATIONS: { path: '/chinh-sach-bao-mat', page: <Regulations />, isProtected: false },
	PROMOTION: { path: '/khuyen-mai', page: <Promotion />, isProtected: false },
};
