import React from 'react';
import {ComponentProp} from '../../constants/types';
import {Container, Menu, MenuItem, Title, Link, Text, ContainerDiv, FooterTextInfo, TextInfo} from './styles/footer';
import {Image} from "../feature/styles/feature";
import Feature from "../feature";

function Footer({children, ...restProps}: ComponentProp) {
    return <ContainerDiv><Container {...restProps}>{children}</Container></ContainerDiv>;
}

Footer.Title = function FooterTitle({children, ...restProps}: ComponentProp) {
    return <Title {...restProps}>{children}</Title>;
};
Footer.Menu = function FooterMenu({children, ...restProps}: ComponentProp) {
    return <Menu {...restProps}>{children}</Menu>;
};
Footer.MenuItem = function FooterMenuItem({children, ...restProps}: ComponentProp) {
    return <MenuItem {...restProps}>{children}</MenuItem>;
};
Footer.Link = function FooterLink({children, ...restProps}: ComponentProp) {
    return <Link {...restProps}>{children}</Link>;
};
Footer.Text = function FooterText({children, ...restProps}: ComponentProp) {
    return <Text {...restProps}>{children}</Text>;
};
Footer.Info = function FooterInfo({children, ...restProps}: ComponentProp) {
    return <FooterTextInfo {...restProps}>{children}</FooterTextInfo>;
};
Footer.TextInfo = function FooterTextInfoI({children, ...restProps}: ComponentProp) {
    return <TextInfo {...restProps}>{children}</TextInfo>;
};
Footer.Image = function FooterImage({ ...restProps }) {
    return <Image {...restProps} />;
};

export default Footer;
