import React, { useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { FooterContainer, HeaderContainer } from '../containers';
import { Form } from '../components';
import {INFO} from "../constants/constants";

function Regulations() {
	const [ isHeaderShown, setHeaderShown ] = useState(false);

	const handleOnScroll = (scrollTop: number) => {
		if (scrollTop > 100 && !isHeaderShown) {
			setHeaderShown(true);
		} else if (scrollTop <= 100 && isHeaderShown) {
			setHeaderShown(false);
		}
	};
	return (
		<Scrollbar noDefaultStyles className="main-scrollbar" onScroll={({ scrollTop }: any) => handleOnScroll(scrollTop)}>
			<HeaderContainer isHeaderShown={isHeaderShown} />
			<Form.Cus>
				<Form.Title>Chính sách bảo mật</Form.Title>
				<Form.Text style={{color: "#ffffff"}}>
					1. Galaxy Links chỉ thu thập thông tin khi có sự đồng ý cung cấp của Quý Khách hàng.
					Những thông tin được Galaxy Links thu thập để phục vụ Quý Khách hàng bao gồm: họ và tên; địa chỉ email; số điện thoại; mật khẩu và một số thông tin bổ sung (trong trường hợp cần thiết).
				</Form.Text>
				<Form.Text style={{color: "#ffffff"}}>
					2. Galaxy Links có thể chia sẻ thông tin của Quý Khách hàng một cách hợp lý trong một số trường hợp đặc biệt như sau:
					<br/>
					Được sự đồng ý của Quý Khách hàng.		<br/>
					Theo yêu cầu của Cơ quan Nhà nước có thẩm quyền.
				</Form.Text>
				<Form.Text style={{color: "#ffffff"}}>
					4. Thông tin về đơn vị thu thập và quản lý thông tin của Quý Khách hàng:
				</Form.Text>
				<Form.Text style={{color: "#ffffff"}}>
					5. Quý Khách hàng có thể thực hiện việc tự chỉnh sửa thông tin cá nhân bằng cách tự truy cập vào website hoặc liên hệ với Galaxy Links qua hotline: {INFO.phone}.
				</Form.Text>
				<Form.Text style={{color: "#ffffff"}}>
					6. Galaxy Links cam kết không tiết lộ thông tin Quý Khách hàng vì mục đích thương mại. Mọi sự chia sẻ và sử dụng thông tin của Quý Khách hàng sẽ được thực hiện theo Quy chế này.
				</Form.Text>
				<Form.Text style={{color: "#ffffff"}}>
					7. Galaxy Links khuyến cáo Quý Khách hàng hạn chế truy cập tài khoản bằng đăng nhập tự động, chú ý chế độ sao lưu mật khẩu và đảm bảo đăng xuất khỏi tài khoản khi sử dụng các thiết bị chung. Galaxy Links sẽ không chịu trách nhiệm khi những thông tin cá nhân của Quý Khách hàng bị rò rỉ vì bất kỳ lý do nào xuất phát từ Quý Khách hàng.
				</Form.Text>
				<Form.Text style={{color: "#ffffff"}}>
					8. Điều khoản bảo mật này chỉ áp dụng những thông tin Quý Khách hàng đăng ký trên hệ thống của Galaxy Links. Mọi thông tin Quý Khách hàng đăng ký tại những hệ thống khác sẽ không được Galaxy Links bảo mật.
				</Form.Text>
			</Form.Cus>
			<FooterContainer />
		</Scrollbar>
	);
}

export default Regulations;
