import React, {useState} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {FooterContainer, HeaderContainer} from '../../containers';
import {Footer, Form} from '../../components';
import {INFO} from "../../constants/constants";
import {Button} from "../../components/header/styles/header";

function Page404() {
    const [isHeaderShown, setHeaderShown] = useState(false);

    const handleOnScroll = (scrollTop: number) => {
        if (scrollTop > 100 && !isHeaderShown) {
            setHeaderShown(true);
        } else if (scrollTop <= 100 && isHeaderShown) {
            setHeaderShown(false);
        }
    };
    return (
        <Scrollbar noDefaultStyles className="main-scrollbar"
                   onScroll={({scrollTop}: any) => handleOnScroll(scrollTop)}>
            <HeaderContainer isHeaderShown={isHeaderShown}/>
            <Form>
                <Form.Title>Opps</Form.Title>
                <Form.Text style={{color: "#ffffff"}}>
                    Kênh truyền hình hoặc phim bạn đang xem không khả dụng, có thể do chương trình không được phép phát
                    sóng hoặc lỗi mạng. Vui lòng liên hệ <Footer.Link href={`mailto:${INFO.email}`}>{INFO.email}</Footer.Link> để được hỗ trợ.
                </Form.Text>
                <Form.ButtonBackHome>
                    Quay lại trang chủ
                </Form.ButtonBackHome>
            </Form>
            <FooterContainer/>
        </Scrollbar>
    );
}

export default Page404;
