import React, {useEffect, useState} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {DetailsContainer, FooterContainer, HeaderContainer} from '../containers';
import {Form, Loading} from '../components';
import {useUser} from "../context/UserContext";
import {Profile, ShowOverview, User} from "../constants/types";
import Profiles from "../components/profiles";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../constants/routes";

function ProfileUser() {
    const [profile, setProfile] = useState<Profile>();
    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(true);
    const [detailsTrailer, setDetailsTrailer] = useState();
    const [heroTrailer, setHeroTrailer] = useState<string | null | undefined>();
    const [category, setCategory] = useState('series');
    const [isHeaderShown, setHeaderShown] = useState(false);
    const [searchResult, setSearchResult] = useState<Array<ShowOverview> | null>();
    const {userDetails, setUserDetails} = useUser();

    useEffect(
        () => {
            setLoading(true);
            const profile = userDetails?.profiles
            if (profile && profile[0] !== undefined) {
                setProfile(profile[0])
            }
            console.log(userDetails)
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            return () => {
                clearTimeout();
            };
        },
        [profile]
    );
    const signout = () => {
        localStorage.clear();
        setUserDetails(undefined);
    };

    const handleOnScroll = (scrollTop: number) => {
        if (scrollTop > 100 && !isHeaderShown) {
            setHeaderShown(true);
        } else if (scrollTop <= 100 && isHeaderShown) {
            setHeaderShown(false);
        }
    };
    const history = useHistory();
    return (
        <Scrollbar noDefaultStyles className="main-scrollbar"
                   onScroll={({scrollTop}: any) => handleOnScroll(scrollTop)}>
            {loading && profile ? <Loading src={profile.avatar}/> : <Loading.ReleaseBody/>}
            {detailsTrailer && <DetailsContainer/>}
            <HeaderContainer
                profile={profile}
                setProfile={setProfile}
                isHeaderShown={isHeaderShown}
                category={category}
                setCategory={setCategory}
                setSearchResult={setSearchResult}
                setHeroTrailer={setHeroTrailer}
            />
            <Profiles.FormProfileDetail>
                <Form.Title>Thông tin tài khoản</Form.Title>
                <Form.TitleProfile>ID Code : {userDetails?.identifier}</Form.TitleProfile>
                <Form.TitleProfile>Tên : {profile?.name}</Form.TitleProfile>
                <Form.TitleProfile>Email : {userDetails?.email}</Form.TitleProfile>
                <Form.TitleProfile>Số điện thoại : {userDetails?.phone}</Form.TitleProfile>
                <Form.TitleProfile>Gói đăng ký : </Form.TitleProfile>
                <Form.TitleProfile>Ngày hết hạn : </Form.TitleProfile>
                <Form.Button onClick={() => history.push(ROUTES.PAGE_CHANGE_PASS.path)}> Đổi mật khẩu </Form.Button>
                <Form.Button style={{backgroundColor: "red", color: "#fff"}} onClick={() => signout()}> Đăng
                    xuất</Form.Button>
            </Profiles.FormProfileDetail>
            <FooterContainer/>
        </Scrollbar>
    );
}

export default ProfileUser;
