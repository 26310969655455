import React, { useState } from "react";
import { Show } from "../components";
import movieHttp from "../api/movie";
import movieGalaxyLinksHttp from "../api/movieGalaxyLinks";
import { SECTIONS, SectionType } from "../api/movieEndpoints";
import {
  GALAXY_LINKS_SECTIONS,
  GALAXY_LINKS_CMS,
  BACKDROP_PLACEHOLDER,
  POSTER_PLACEHOLDER,
  IMAGE_BASE_URL,
  IMAGE_SIZES,
} from "../constants/constants";
import { usePlayer } from "../context/PlayerContext";
import { ShowOverview, Video, GalaxyLinkGenreType } from "../constants/types";
import ReactPlayer from "react-player";
import { isAndroid, isIOS } from "react-device-detect";
import Poster from "../context/mumbai.jpg";
type ShowContainerType = {
  section: SectionType;
  show: ShowOverview;
  galaxyLinksGenres?: Array<GalaxyLinkGenreType>;
};
function ShowContainer({
  section,
  show,
  galaxyLinksGenres,
}: ShowContainerType) {
  const {
    category: { category },
    detailsTrailer: { setDetailsTrailer },
    trailerDisplayed: { trailerDisplayed, setTrailerDisplayed },
    heroTrailer: { setHeroTrailer },
    isMuted: { isMuted, setIsMuted },
    genres: { genres },
  } = usePlayer();

  var IMAGE_BASE_URL_FINAL = IMAGE_BASE_URL;

  const showPoster =
    (trailerDisplayed &&
      ((trailerDisplayed.id === show.id.toString() &&
        trailerDisplayed.header !== section.title) ||
        trailerDisplayed.id !== show.id.toString())) ||
    !trailerDisplayed;
  const playerRef = React.createRef<ReactPlayer>();
  const [delayHandler, setDelayHandler] = useState<any>(null);
  const windowWidth = window.innerWidth;
  const handleShowHover = async () => {
    setDelayHandler(
      setTimeout(async () => {
        try {
          var endpoint =
            category === "series"
              ? SECTIONS.series.helpers.fetchTVVideos.replace(
                  "{{tv_id}}",
                  show.id.toString()
                )
              : SECTIONS.movies.helpers.fetchMovieVideos.replace(
                  "{{movie_id}}",
                  show.id.toString()
                );
          var response = { data: { results: [] } };

          if (GALAXY_LINKS_SECTIONS.indexOf(section.title) != -1) {
            endpoint =
              SECTIONS.movies.helpers.fetchMovieVideosGalaxyLinks.replace(
                "{{movie_id}}",
                show.id.toString()
              );
            response = await movieGalaxyLinksHttp.get(endpoint);
          } else {
            response = await movieHttp.get(endpoint);
          }
          //const trailerDetails: Video = response.data.results
          const trailerDetails: any = response.data.results
            .reverse()
            //.find((video: Video) => video.site === 'YouTube' && video.type === 'Trailer');
            .find(
              (video: any) =>
                video.site === "YouTube" && video.type === "Trailer"
            );

          if (trailerDetails) {
            setTrailerDisplayed({
              id: show.id.toString(),
              header: section.title,
              sectionName: section.title,
              url: trailerDetails.key,
              isLoaded: false,
            });
            setHeroTrailer();
          }
        } catch (e) {
          console.log(e);
        }
      }, 800)
    );
  };

  if (GALAXY_LINKS_SECTIONS.indexOf(section.title) != -1) {
    IMAGE_BASE_URL_FINAL = GALAXY_LINKS_CMS;
  }
  var LARGE_IMAGE_URL = `${
    IMAGE_BASE_URL_FINAL +
    (windowWidth <= 600
      ? IMAGE_SIZES.poster.small
      : windowWidth <= 1000
      ? IMAGE_SIZES.poster.medium
      : IMAGE_SIZES.poster.large) +
    show.poster_path
  }`;

  var NORMAL_IMAGE_URL = `${
    IMAGE_BASE_URL_FINAL + IMAGE_SIZES.backdrop.small + show.backdrop_path
  }`;

  if (GALAXY_LINKS_SECTIONS.indexOf(section.title) != -1) {
    LARGE_IMAGE_URL = `${IMAGE_BASE_URL_FINAL + "/movies" + show.poster_path}`;
    NORMAL_IMAGE_URL = `${IMAGE_BASE_URL_FINAL + "/movies" + show.poster_path}`;
  }

  const onTrailerReady = () => {
    if (trailerDisplayed)
      setTrailerDisplayed({ ...trailerDisplayed, isLoaded: true });
  };

  const calculateIfNew = (dateString: string) => {
    const movieDate = new Date(dateString);
    const currentDate = new Date();
    const difference = currentDate.getTime() - movieDate.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24)) <= 30;
  };

  return (
    <Show>
      <Show.Card
        onMouseEnter={() => handleShowHover()}
        onMouseLeave={() => {
          clearTimeout(delayHandler);
          setTrailerDisplayed(null);
        }}
        onClick={() => {
          let url = show["trailer"] || "";
          if (isAndroid) {
            url = show["android_url"] || "";
          }
          if (isIOS) {
            url = show["ios_url"] || "";
          }
          setDetailsTrailer({
            id: show.id,
            sectionName: section.title,
            key: url ? url : null,
            start:
              playerRef && playerRef.current
                ? playerRef.current.getCurrentTime()
                : 0,
          });
        }}
      >
        {!showPoster && trailerDisplayed && trailerDisplayed.url && (
          <Show.Video
            src={trailerDisplayed.url}
            playerRef={playerRef}
            isMuted={isMuted}
            setIsMuted={setIsMuted}
            onUpdateTrailer={setTrailerDisplayed}
            onTrailerReady={onTrailerReady}
            className={trailerDisplayed.isLoaded ? "trailer-visible" : ""}
          />
        )}
        {(showPoster ||
          (trailerDisplayed &&
            trailerDisplayed.id === show.id.toString() &&
            !trailerDisplayed.isLoaded)) && (
          <div className="content__img" style={{ position: "relative" }}>
            <Show.Poster
              src={
              	section.size === 'lg' ? show.poster_path ? (
              		`${LARGE_IMAGE_URL}`
              	) : (
              		POSTER_PLACEHOLDER
              	) : show.backdrop_path ? (
              		`${NORMAL_IMAGE_URL}`
              	) : (
              		BACKDROP_PLACEHOLDER
              	)
              }
              alt={show.name || show.title}
            />
            <Show.Label>{show.label}</Show.Label>
          </div>
        )}

        <Show.Details>
          <Show.Title>{show.name || show.title}</Show.Title>
          <br />
          <Show.Rating>
            {calculateIfNew(show.release_date || show.first_air_date) && (
              <span className="new-badge">New</span>
            )}
            <span className="rating">
              {show.vote_average} by {show.vote_count.toLocaleString()} people
            </span>
          </Show.Rating>
          {section.size !== "lg" && (
            <React.Fragment>
              <br />
              <p>
                {genres && genres.length > 0
                  ? show.genre_ids.map((genreId, i) => {
                      if (i > 2) return null;
                      var genreDetails = genres.find(
                        (genre) => genre.id === genreId
                      );
                      if (GALAXY_LINKS_SECTIONS.indexOf(section.title) != -1) {
                        genreDetails = galaxyLinksGenres?.find(
                          (genre: any) => genre.id === genreId
                        );
                      }
                      return (
                        <Show.Genre
                          key={`${show.id}_${genreId}`}
                          genreDetails={genreDetails}
                          length={show.genre_ids.length}
                          i={i}
                        />
                      );
                    })
                  : null}
              </p>
            </React.Fragment>
          )}
        </Show.Details>
      </Show.Card>
    </Show>
  );
}

export default ShowContainer;
