import React, { useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { FooterContainer, HeaderContainer } from '../containers';
import { Form } from '../components';
import {INFO} from "../constants/constants";

function Policy() {
	const [ isHeaderShown, setHeaderShown ] = useState(false);

	const handleOnScroll = (scrollTop: number) => {
		if (scrollTop > 100 && !isHeaderShown) {
			setHeaderShown(true);
		} else if (scrollTop <= 100 && isHeaderShown) {
			setHeaderShown(false);
		}
	};
	return (
		<Scrollbar noDefaultStyles className="main-scrollbar" onScroll={({ scrollTop }: any) => handleOnScroll(scrollTop)}>
			<HeaderContainer isHeaderShown={isHeaderShown} />
			<Form.Cus>
				<Form.Title>Quy chế sử dụng dịch vụ</Form.Title>
				<Form.TextCus style={{color: "#ffffff"}}>
					Dịch vụ Xem phim theo yêu cầu Galaxy Links (“Dịch vụ Galaxy Links”) là dịch vụ do Công ty Cổ phần Galaxy Links cung cấp, cho phép người dùng có thể truy cập để xem phim và các nội dung video khác (“Nội dung”) vào bất cứ thời gian nào người dùng mong muốn trên các thiết bị và/hoặc nền tảng khác nhau thông qua mạng Internet.
					<br/>
					Bằng việc sử dụng dịch vụ của Galaxy Links, Quý Khách hàng đồng ý và chấp thuận bị ràng buộc về pháp lý bởi các quyền và nghĩa vụ phát sinh theo quy định tại Quy chế này. Galaxy Links có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào trong Quy định và Điều kiện sử dụng, vào bất cứ lúc nào mà không cần thông báo trước. Quý khách vui lòng kiểm tra thường xuyên để cập nhật những thay đổi của Galaxy Links
				</Form.TextCus>
				<Form.Title>Đăng ký tài khoản</Form.Title>
				<Form.TextCus style={{color: "#ffffff"}}>
					1. Để sử dụng Dịch vụ Galaxy Links, Quý Khách hàng phải đăng ký tài khoản khách hàng thông qua thiết bị hoặc nền tảng có thể truy cập vào Dịch vụ Galaxy Links và cung cấp cho Galaxy Links các thông tin cần thiết để xác nhận tài khoản. Quý Khách hàng có trách nhiệm cập nhật và đảm bảo tính đầy đủ, chính xác của các thông tin đã cung cấp cho Galaxy Links. Quý Khách hàng có thể kiểm tra thông tin tài khoản tại website www.galaxyplay.vn.
					<br/>
					2. Để đăng ký và sử dụng tài khoản, Quý Khách hàng phải là người đủ 18 tuổi và có năng lực hành vi dân sự đầy đủ. Trẻ em và người vị thành niên chỉ có thể sử dụng Dịch vụ Galaxy Links khi được sự cho phép và giám sát của Quý Khách hàng.
					<br/>
					3. Galaxy Links có quyền yêu cầu Quý Khách hàng cung cấp giấy tờ, bằng chứng chứng minh về độ tuổi và các thông tin xác nhận tài khoản.
					<br/>
					4. Quý Khách hàng phải đăng ký một phương thức thanh toán hợp lệ và phù hợp với thiết bị hoặc nền tảng mà Quý Khách hàng đang sử dụng (“Phương Thức Thanh Toán”). Quý Khách hàng đồng ý cho phép Galaxy Links thu tiền sử dụng Dịch vụ Galaxy Links thông qua Phương Thức Thanh Toán đã đăng ký.
					<br/>
					5. Quý Khách hàng có trách nhiệm bảo mật tài khoản đã đăng ký thông qua việc bảo mật mật khẩu và các thông tin liên quan đến tài khoản của Quý Khách hàng. Đồng thời, Quý Khách hàng cần bảo quản cẩn thận các thiết bị đã dùng để truy cập Dịch vụ Galaxy Links nhằm bảo mật tài khoản của Quý Khách hàng. Galaxy Links không chịu trách nhiệm về bất kỳ thiệt hại nào phát sinh từ việc tài khoản của Quý Khách hàng bị sử dụng trái phép. Đồng thời, Galaxy Links có quyền tạm dừng hoặc chấm dứt việc sử dụng tài khoản của Quý Khách hàng với mục đích bảo vệ cho Quý Khách hàng, Galaxy Links hoặc các đối tác của chúng tôi khỏi các hành vi sai trái, vi phạm pháp luật.
				</Form.TextCus>
			</Form.Cus>
			<FooterContainer />
		</Scrollbar>
	);
}

export default Policy;
