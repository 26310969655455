import React, {useEffect, useState} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {DetailsContainer, FooterContainer, HeaderContainer} from '../containers';
import {Form, Loading} from '../components';
import {useUser} from "../context/UserContext";
import {Profile, ShowOverview} from "../constants/types";
import mainHttp from "../api/mainEndpoints";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../constants/routes";

function ChangePass() {
    const {userDetails} = useUser();
    const history = useHistory();
    const [profile, setProfile] = useState<Profile>();
    const [id, setId] = useState(userDetails?._id);
    const [email, setEmail] = useState(userDetails?.email);
    const [loading, setLoading] = useState(true);
    const [detailsTrailer, setDetailsTrailer] = useState();
    const [heroTrailer, setHeroTrailer] = useState<string | null | undefined>();
    const [category, setCategory] = useState('series');
    const [isHeaderShown, setHeaderShown] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordNew, setPasswordNew] = useState('');
    const [passwordNewMatch, setPasswordNewMatch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isTouched, setIsTouched] = useState({passwordNew: false, password: false, passwordNewMatch: false});
    const passwordInvalid = isTouched.password && password === '';
    const passwordNewInvalid = isTouched.passwordNew && passwordNew === '';
    const passwordNewMatchInvalid = isTouched.passwordNewMatch && passwordNewMatch === '';
    const [messageError, setMessageError] = useState('');
    const canProceed = passwordNew && !passwordNewInvalid && passwordNewMatch && !passwordNewMatchInvalid;


    useEffect(
        () => {
            setLoading(true);
            const profile = userDetails?.profiles
            if (profile && profile[0] !== undefined) {
                setProfile(profile[0])
            }
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            return () => {
                clearTimeout();
            };
        },
        [profile]
    );

    const handleChangePass = (e: any) => {
        e.preventDefault();
        setMessageError('')
        setIsLoading(true);
        if (password === passwordNew) {
            setMessageError('Mật khẩu không được trùng với mật khẩu hiện tại')
        } else if (passwordNewMatch !== passwordNew) {
            setMessageError('Mật khẩu mới không trùng khớp')
        } else if (canProceed) {
            mainHttp
                .changePass({id, email, password, passwordNew, passwordNewMatch})
                .then(({ success, message }: any) => {
                    alert(message)
                    setIsLoading(false);
                    if (success) {
                        history.push(ROUTES.BROWSE.path);
                    }
                })
                .catch(({response}) => {
                    setIsLoading(false);
                });
        }
        setIsLoading(false);
    };

    const handleOnScroll = (scrollTop: number) => {
        if (scrollTop > 100 && !isHeaderShown) {
            setHeaderShown(true);
        } else if (scrollTop <= 100 && isHeaderShown) {
            setHeaderShown(false);
        }
    };

    return (
        <Scrollbar noDefaultStyles className="main-scrollbar"
                   onScroll={({scrollTop}: any) => handleOnScroll(scrollTop)}>
            {loading && profile ? <Loading src={profile.avatar}/> : <Loading.ReleaseBody/>}
            {detailsTrailer && <DetailsContainer/>}
            <HeaderContainer
                profile={profile}
                setProfile={setProfile}
                isHeaderShown={isHeaderShown}
                category={category}
                setCategory={setCategory}
                setHeroTrailer={setHeroTrailer}
            />
            <Form>
                <Form.FormGroup onSubmit={handleChangePass} method="POST">
                    <Form.Title>Thay đổi mật khẩu</Form.Title>
{/*                    <Form.Input
                        type="password"
                        placeholder="Mật khẩu"
                        autoComplete="off"
                        value={password}
                        onChange={({ target }: any) => {
                            if (!isTouched.password)
                                setIsTouched((prevIsTouched) => {
                                    return { ...prevIsTouched, password: true };
                                });
                            setPassword(target.value);
                        }}
                        className={passwordInvalid ? 'has-error' : ''}
                    />
                    {passwordInvalid && <Form.Error>Vui lòng nhập mật khẩu của bạn.</Form.Error>}*/}
                    <Form.Input
                        type="password"
                        placeholder="Mật khẩu mới"
                        autoComplete="off"
                        value={passwordNew}
                        onChange={({ target }: any) => {
                            if (!isTouched.passwordNew)
                                setIsTouched((prevIsTouched) => {
                                    return { ...prevIsTouched, passwordNew: true };
                                });
                            setPasswordNew(target.value);
                        }}
                        className={passwordNewInvalid ? 'has-error' : ''}
                    />
                    {passwordInvalid && <Form.Error>Vui lòng nhập mật khẩu mới của bạn.</Form.Error>}
                    <Form.Input
                        type="password"
                        placeholder="Nhập lại mật khẩu mới"
                        autoComplete="off"
                        value={passwordNewMatch}
                        onChange={({ target }: any) => {
                            if (!isTouched.passwordNewMatch)
                                setIsTouched((prevIsTouched) => {
                                    return { ...prevIsTouched, passwordNewMatch: true };
                                });
                            setPasswordNewMatch(target.value);
                        }}
                        className={passwordNewInvalid ? 'has-error' : ''}
                    />
                    {passwordInvalid && <Form.Error>Vui lòng nhập mật khẩu mới của bạn.</Form.Error>}
                    {messageError && <Form.Error>{messageError}</Form.Error>}
                    <Form.Button disabled={!canProceed || isLoading} type="submit">
                        {isLoading ? (
                            <React.Fragment>
                                <Form.Spinner />Đang xử lý...
                            </React.Fragment>
                        ) : (
                            'Đổi mật khẩu'
                        )}
                    </Form.Button>
                </Form.FormGroup>
            </Form>
            <FooterContainer/>
        </Scrollbar>
    );
}

export default ChangePass;
