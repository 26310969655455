import styled from 'styled-components/macro';
import { IMAGE_BASE_URL, IMAGE_SIZES } from '../../../constants/constants';
import { isIOS } from "react-device-detect";

interface OverlayProps {
	readonly fullOverlay?: boolean;
}
interface BannerProps {
	readonly src: string;
	readonly windowWidth: number;
}

export const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: #141414;
	display: flex;
	place-content: center;
	text-align: center;
	background: url('/images/misc/galaxy_links_bg_04.png');
	background-size: cover;
	overflow: hidden;
	align-items: center;
	
	@media (max-width: 600px) {
		overflow: scroll;
	}
`;

export const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 0;
	background: ${({ fullOverlay }: OverlayProps) =>
		(fullOverlay ? `linear-gradient(rgba(20, 20, 20, 0) 60%, rgba(20, 20, 20, 0.95)), ` : '') +
		'linear-gradient(0.25turn, rgba(20, 20, 20, 0.75), rgba(20, 20, 20, 0))'};

	@media (max-width: 1000px) {
		height: 50vh;
	}
	@media (max-width: 600px) {
		height: 30vh;
	}
`;

export const Banner = styled.div`
	width: 100%;
	height: 100vh;
	background: ${({ src, windowWidth }: BannerProps) =>
		`linear-gradient(rgba(20,20,20,0) 60%, rgba(20,20,20,0.95)), url("${IMAGE_BASE_URL +
			(windowWidth <= 600
				? IMAGE_SIZES.backdrop.xmedium
				: windowWidth <= 1000 ? IMAGE_SIZES.backdrop.medium : IMAGE_SIZES.backdrop.large) +
			src}") center`};
	background-size: cover;
	z-index: 0;

	@media (max-width: 1000px) {
		height: 50vh;
	}
	@media (max-width: 600px) {
		height: 30vh;
	}
`;

export const ImageChannel = styled.img`
	width: 80%;
	border: 3px solid transparent;
	border-radius: 0.5rem;
	transition: all 0.1s ease-in;
	@media (max-width: 1300px) {
		width: 60%;
		border: 2px solid transparent;
	}
	@media (max-width: 728px) {
		width: 80%;
	}

`;

export const ImageDetail = styled.img`
	width: 80%;
	border: 3px solid transparent;
	border-radius: 0.5rem;
	transition: all 0.1s ease-in;
	@media (max-width: 1300px) {
		border: 2px solid transparent;
	}
`;
export const ImageDetailPackage = styled.img`
	width: 100%;
	border: 3px solid transparent;
	border-radius: 0.5rem;
	transition: all 0.1s ease-in;
	@media (max-width: 1300px) {
		border: 2px solid transparent;
	}
`;

export const Name = styled.p`
	color: white;
	font-size: 1.65rem;
	font-weight: 400;
	margin: 0.5rem 0 0;
	transition: all 0.1s ease-in;
	@media (max-width: 1000px) {
		font-size: 0.8rem;
	}
`;

export const DivSlider = styled.div`
	width: 35%;
	@media (max-width: 1300px) {
		width: 40%;
	}
	@media (max-width: 728px) {
		width: 100%;
		height: 70%;
	}
`;

export const DivDetailChannel = styled.div`
	width: 65%;
	@media (max-width: 1300px) {
		width: 60%;
	}
	@media (max-width: 780px) {
		${isIOS ? 'height: 15vh;' : 'height: 30vh;'}
		width: 100%;
		position: absolute;
	}
`;


export const DetailChannel = styled.div`

`;

export const TextDetailChannel = styled.h2`
	margin-left: 10%;
	text-align: left;
	margin-bottom: 3%;
	margin-top: 5%;
`;

export const TextDescription = styled.h4`
	margin-left: 10%;
	margin-right: 10%;
	text-align: left;
	margin-bottom: 3%;
`;

export const TextDescriptionPackages = styled.h4`
	font-size: 0.9rem;
	text-align: left;
	margin-bottom: 3%;
`;

export const ButtonGroupChannelDetail = styled.div`
	margin-left: 10%;
	text-align: left;
`;

export const ButtonProgramProgress = styled.button`
	outline: none;
	border: 1px solid #f58020;
	color: white;
	font-weight: 500;
	font-size: 0.85rem;
	letter-spacing: 2px;
	margin: 1.5rem;
	padding: 1rem 1.5rem;
	cursor: pointer;
	transition: all 0.1s ease-in;
	background-color: rgba(222, 222, 222, 0.1);

	&:hover {
		border: 1px solid #fff;
		color: #fff;
	}
	&.white-btn {
		background-color: #fff;
		border: 1px solid #fff;
		color: #141414;
		&:hover {
			background-color: #f26b38;
			border: 1px solid #f26b38;
			color: #fff;
		}
		&:disabled {
			background-color: #565656;
			color: #969696;
			cursor: default;
			border: 1px solid #969696;
		}
	}

	@media (max-width: 1000px) {
		font-size: 0.85rem;
		padding: 0.5rem 1rem;
		margin-top: 3rem;
	}

	@media (max-width: 780px) {
		margin: 0.5rem;
	}
`;

export const Button = styled.button`
	outline: none;
	background-color: #f58020;
	border: 1px solid #f58020;
	color: white;
	font-weight: 500;
	font-size: 0.85rem;
	letter-spacing: 2px;
	margin: 1.5rem;
	padding: 1rem 1.5rem;
	cursor: pointer;
	transition: all 0.1s ease-in;

	&:hover {
		border: 1px solid #fff;
		color: #fff;
	}
	&.white-btn {
		background-color: #fff;
		border: 1px solid #fff;
		color: #141414;
		&:hover {
			background-color: #f26b38;
			border: 1px solid #f26b38;
			color: #fff;
		}
		&:disabled {
			background-color: #565656;
			color: #969696;
			cursor: default;
			border: 1px solid #969696;
		}
	}

	@media (max-width: 1000px) {
		font-size: 0.85rem;
		padding: 0.5rem 1rem;
		margin-top: 3rem;
	}

	@media (max-width: 780px) {
		margin: 0.5rem;
	}
`;

