import React, { useState } from 'react';
import {
    Container,
    DivDetailChannel,
    DivSlider,
    ImageChannel,
    DetailChannel,
    Button,
    TextDetailChannel,
    TextDescription,
    TextDescriptionPackages,
    ButtonGroupChannelDetail, ImageDetail, ButtonProgramProgress, ImageDetailPackage
} from './styles/tvShow';
import {ComponentProp} from '../../constants/types';
import {ROUTES} from "../../constants/routes";

function tvShow({children, ...restProps}: ComponentProp) {
    return <Container {...restProps}>{children}</Container>;
}

tvShow.DivSlider = function TVShowDivSlider({children, ...restProps}: ComponentProp) {
    return <DivSlider>{children}</DivSlider>;
}

tvShow.DivDetailChannel = function TVShowDivDetailChannel({children, ...restProps}: ComponentProp) {
    return <DivDetailChannel>{children}</DivDetailChannel>;
}


tvShow.Detail = function TVShowDetail({children, ...restProps}: ComponentProp) {
    return <DetailChannel>{children}</DetailChannel>;
}

tvShow.ImageChannel = function TVShowAvatar({src, ...restProps}: ComponentProp) {
    return <ImageChannel src={`/images/tvShow/${src}`} {...restProps} />;
};

tvShow.ImageDetail = function TVShowImageDetail({src, ...restProps}: ComponentProp) {
    return <ImageDetail src={`/images/tvShow/${src}`} {...restProps} />;
};

tvShow.Button = function TVShowButton({ children, ...restProps }: ComponentProp) {
    return <Button {...restProps}>{children}</Button>;
};

tvShow.TextDetailChannel = function TVShowTextDetailChannel({ children, ...restProps }: ComponentProp) {
    return <TextDetailChannel {...restProps}>{restProps.text || ''}</TextDetailChannel>;
};

tvShow.TextDescription = function TVShowTextDescription({ children, ...restProps }: ComponentProp) {
    return <TextDescription {...restProps}>{restProps.text || ''}</TextDescription>;
};

tvShow.TextDescriptionPackage = function TVShowTextDescriptionPackage({ children, ...restProps }: ComponentProp) {
    return <TextDescriptionPackages {...restProps}>{restProps.text || ''}</TextDescriptionPackages>;
};

tvShow.ButtonGroupChannelDetail = function TVShowButtonGroupChannelDetail({ children, ...restProps }: ComponentProp) {
    return <ButtonGroupChannelDetail {...restProps}>{children}</ButtonGroupChannelDetail>;
};

tvShow.ButtonProgramProgress = function TVShowButtonProgramProgress({ children, ...restProps }: ComponentProp) {
    return <ButtonProgramProgress {...restProps}>{children}</ButtonProgramProgress>;
};

tvShow.Slider = function TVShowSlider({setDataDetailChannel, handleClick, data, ...restProps}: ComponentProp) {
    return (
        <ul className={'carousel'}>
            {
                data && Object.keys(data).map((key) => {
                    return  (<li className={'item'} onClick={() => handleClick(key)}>
                        <ImageChannel src={`/images/tvShow/${data[key]['logo']}`} {...restProps} />
                    </li>)
                })
            }
        </ul>
    );
};
tvShow.ImageDetailPackage = function TVShowImageDetailPackage({src, ...restProps}: ComponentProp) {
    return <ImageDetailPackage src={`/images/tvShow/${src}`} {...restProps} />;
};

export default tvShow;
