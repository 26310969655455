import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Container, Overlay, Details, Title, Video, Back } from './styles/player';
import ReactPlayer from 'react-player';
import { ComponentProp } from '../../constants/types';

function Player({ children, ...restProps }: ComponentProp) {
	return <Container {...restProps}>{children}</Container>;
}

Player.Back = function PlayerBack({ ...restProps }) {
	return (
		<Back {...restProps}>
			<ArrowBackIcon />
		</Back>
	);
};

Player.Overlay = function PlayerOverlay({ ...restProps }) {
	return <Overlay {...restProps} />;
};

Player.Details = function PlayerDetails({ children, ...restProps }: ComponentProp) {
	return <Details {...restProps}>{children}</Details>;
};

Player.Title = function PlayerTitle({ children, ...restProps }: ComponentProp) {
	return <Title {...restProps}>{children}</Title>;
};

Player.Video = function PlayerVideo({setUrl, setIsPaused, ...restProps}: ComponentProp) {
	return (
		<Video {...restProps}>
			<ReactPlayer
				//url="/videos/cinematic.m4v"
				//url="http://vod.galaxylinks.vn/GLGSF/mp4:70BIGONE_23.976fps_5.1.mp4/playlist.m3u8"
				url={setUrl || ''}
				className="playing-video"
				width="100%"
				height="100%"
				playing
				controls
				config={{file: {attributes: {disablePictureInPicture: true}}}}
				onPause={() => setIsPaused(true)}
				onPlay={() => setIsPaused(false)}
			/>
		</Video>
	);
};

export default Player;
