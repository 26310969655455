import styled from 'styled-components/macro';

export const Container = styled.div`
	display: flex;
	height: 110vh;
	background: url('/images/misc/Home_Banner.jpg');
	background-size: cover;
	background-position: 50% 50%;
	//border-bottom: 0.5rem solid #222;
    //background-image : url('/images/misc/Home_Banner.jpg');

	@media (max-width: 1000px) {
		height: 75vh;
	}
	@media (max-width: 600px) {
		height: 55vh;
	}
`;

export const Inner = styled.div`
	max-width: 50rem;
	margin: auto;
  
	text-align: center;
	color: #fff;
    padding-top: 7%;

	@media (max-width: 1000px) {
		max-width: 40rem;
	}
`;

export const Title = styled.h1`
	margin-bottom: 0;
	font-size: 4rem;

	@media (max-width: 1000px) {
		font-size: 3rem;
	}
	@media (max-width: 600px) {
		font-size: 1.25rem;
		margin-top: 6rem;
	}
`;

export const SubTitle = styled.h2`
	font-weight: 500;
	margin-bottom: 0;
	font-size: 1.75rem;

	@media (max-width: 1000px) {
		font-size: 1.5rem;
	}
	@media (max-width: 600px) {
		font-size: 1.15rem;
	}
`;
