import React, {useState, useEffect, useRef} from 'react';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {Form, Hero, TVShow} from '../components';
import movieHttp from '../api/movie';
import mainHttp from '../api/main';
import {SECTIONS} from '../api/movieEndpoints';
import {usePlayer} from '../context/PlayerContext';
import {Profile, ShowOverview, Video} from '../constants/types';
import ReactPlayer from 'react-player';
import TvShow from "../components/tvShow";
import {
    isSafari,
    isAndroid,
    isIOS
} from "react-device-detect";
import {ImageDetailPackage} from "../components/tvShow/styles/tvShow";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../constants/routes";

declare var $: any;

type HeroContainerType = {
    profile?: Profile;
};

function HeroContainer({profile}: HeroContainerType) {
    const [banner, setBanner] = useState<ShowOverview | null>();
    const [dataTVShow, setDataTVShow] = useState([]);
    const [dataURL, setDataURL] = useState(null);
    const history = useHistory();
    const [dataDetailChannel, setDataDetailChannel] = useState({
        'title': '',
        'description': '',
        'image': '',
        'url': '',
        'id': '',
        'logo': '',
        'isBlocked': false
    });
    const playerRef = useRef<ReactPlayer>(null);
    const [isLoadJquery, setIsLoadJquery] = useState(true);
    const {
        category: {category},
        playing: {setPlaying},
        isMuted: {isMuted, setIsMuted},
        detailsTrailer: {setDetailsTrailer},
        heroTrailer: {heroTrailer, setHeroTrailer}
    } = usePlayer();
    const windowWidth = window.innerWidth;

    useEffect(() => {
        async function fetchDataTVShow() {
            const response = await mainHttp.get(SECTIONS['television'].sections[1].endpoint);
            setDataTVShow(response.data.results);
            const detailChannel = response.data && response.data.results[0] || '';
            const blockTime = JSON.parse(detailChannel.time_blocked)['data'];
            const isBlocked = isBlockTime(blockTime)
            if (detailChannel && detailChannel !== undefined) {
                let url = detailChannel['trailer'] || ''
                if (isAndroid) {
                    url = detailChannel['android_url'] || ''
                }
                if (isIOS) {
                    url = detailChannel['ios_url'] || ''
                }
                setDataDetailChannel({
                    'title': detailChannel['title'] || '',
                    'description': detailChannel['description'] || '',
                    'logo': detailChannel['logo'] || '',
                    'image': isBlocked ? 'bao-tri.png' : detailChannel['imgHash'],
                    'url': url,
                    'id': detailChannel['id'] || '',
                    'isBlocked': isBlocked
                })
            }
        }

        fetchDataTVShow();
    }, []);

    useEffect(
        () => {
            async function fetchData() {
                const response = await mainHttp.get(SECTIONS['movies'].sections[2].endpoint);
                const bannerDetailData = response.data.results[Math.floor(Math.random() * response.data.results.length)];
                let url = bannerDetailData['trailer'] || ''
                if (isAndroid) {
                    url = bannerDetailData['android_url'] || ''
                }
                if (isIOS) {
                    url = bannerDetailData['ios_url'] || ''
                }
                setDataURL(url)
                const bannerDetails = {
                    "backdrop_path": `/images/movies${bannerDetailData['backdrop_path'] || ''}`,
                    "first_air_date": "2014-10-07",
                    "genre_ids": bannerDetailData['genre_ids'] || [],
                    "id": bannerDetailData['id'] || [],
                    "name": bannerDetailData['title'] || '',
                    "origin_country": [
                        "US"
                    ],
                    "original_language": "en",
                    "original_name": bannerDetailData['title'] || '',
                    "overview": bannerDetailData['description'] || '',
                    "popularity": 752.59,
                    "poster_path": `/images/movies${bannerDetailData['backdrop_path'] || ''}`,
                    "vote_average": 7.8,
                    "vote_count": 9646,
                    "category": category || null
                };
                // setBanner(ob);
                setBanner(bannerDetails);
                setHeroTrailer(url)
                if (windowWidth > 600) {

                }
            }

            fetchData();
        },
        [windowWidth, profile, category, setHeroTrailer]
    );

    const truncate = (string: string, length: number) => {
        return string.length > length ? string.substr(0, length - 1) + '...' : string;
    };

    const isBlockTime = (blockTime: any) => {
        let isBlock = false
        if (blockTime instanceof Array) {
            blockTime.map((check, idx, []) => {
                const date = new Date().getTime()
                const startDate = new Date(`${check?.DateBlocked} ${check?.startTime}`).getTime()
                const endDate = new Date(`${check?.DateBlocked} ${check?.endTime}`).getTime()
                if (startDate <= date && endDate >= date) {
                    isBlock = true
                }
            });
        }
        return isBlock
    };

    const handleClick = (key: number) => {
        const detailChannel = dataTVShow[key] || '';
        if (detailChannel && detailChannel !== undefined) {

            const blockTime = JSON.parse(detailChannel['time_blocked']);
            const blockTimeCheck = blockTime ? blockTime['data'] : []
            const isBlocked = isBlockTime(blockTimeCheck)
            let url = detailChannel['trailer'] || ''
            if (isAndroid) {
                url = detailChannel['android_url'] || ''
            }
            if (isIOS) {
                url = detailChannel['ios_url'] || ''
            }
            setDataDetailChannel({
                'title': detailChannel['title'] || '',
                'id': detailChannel['id'] || '',
                'description': detailChannel['description'] || '',
                'image': isBlocked ? 'bao-tri.png' : detailChannel['imgHash'],
                'url': url,
                'logo': detailChannel['logo'] || '',
                'isBlocked': isBlocked
            })
            setIsLoadJquery(false)
        }
    };


    const loadJquery = () => {
        let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
        var container = $('.carousel');
        var fields = $('.item');
        var width = container.width() / 2;
        var height = container.width();
        var radius = width / 2.5;
        var angle = 0, step = (2 * Math.PI) / fields.length;
        var x = Math.round(width / 2 + radius * Math.cos(angle) - $().width() / 2);
        var y = Math.round(height / 2 + radius * Math.sin(angle) - $().height() / 2);

        var options = {
            ovalWidth: width,
            ovalHeight: x,
            offsetX: 10,
            offsetY: 60,
            angle: -90,
            activeItem: 0,
            duration: 350,
            className: 'item'
        }

        if (isMobile) {
            options = {
                ovalWidth: 300,
                ovalHeight: 50,
                offsetX: 10,
                offsetY: 10,
                angle: 0,
                activeItem: 0,
                duration: 200,
                className: 'item'
            }
        }

        var carousel = $('.carousel').CircularCarousel(options);

        /* Fires when an item is about to start it's activate animation */
        carousel.on('itemBeforeActive', function () {
            $(arguments[1]).css('box-shadow', '0 0 0 green');
        });

        /* Fires after an item finishes it's activate animation */
        carousel.on('itemActive', function () {
            const activeItemIndex = $(arguments[1]).index('li')
            var lengthItem = parseInt(fields.length) - 1
            var arrayCheckHidden = [lengthItem, lengthItem - 1];

            $('.carousel').find('.item').each(function () {
                const index = $(arguments[1]).index('li');
                const difference = Math.abs(index - activeItemIndex);
                //  console.log(activeItemIndex, 3, $.inArray(difference, arrayCheckHidden) >= 0, difference, $.inArray(activeItemIndex, [0, 1]) >= 0)
                // 0   3  9 8
                // 1   3  8
                // 2   3  ''
                // 3   3  ''
                // 4   3  ''
                // 5   3  ''
                // 6   3  ''
                // 8   3  8
                // 9   3  9 8
                if (difference < 3) {
                    $(arguments[1]).show()
                } else if ($.inArray(difference, arrayCheckHidden) >= 0) {
                    $(arguments[1]).show()
                } else {
                    $(arguments[1]).hide()
                }
                if (difference != 0) {
                    $(arguments[1]).css('opacity', '0.5');
                } else {
                    $(arguments[1]).css('opacity', '1');
                }
            });
        });

        /* Fires when an active item starts it's de-activate animation */
        carousel.on('itemBeforeDeactivate', function () {
            $(arguments[1]).css('box-shadow', '0 0 0 green');
        })

        /* Fires after an active item has finished it's de-activate animation */
        carousel.on('itemAfterDeactivate', function () {
            $(arguments[1]).css('box-shadow', '');
        })


        /* Previous button */
        $('.controls .previous').click(function () {
            carousel.cycleActive('previous');
            arguments[0].preventDefault();
        });

        /* Next button */
        $('.controls .next').click(function () {
            carousel.cycleActive('next');
            arguments[0].preventDefault();
        });

        /* Manaully click an item anywhere in the carousel */
        $('.carousel .item').click(function () {
            var index = $(arguments[0].currentTarget).index('li');
            carousel.cycleActiveTo(index);
            arguments[0].preventDefault();
        });
    }

    if (category === 'movies') {
        let urlMovies = dataURL || null
        if (!isLoadJquery) {
            setIsLoadJquery(true)
        }
        if (isSafari && isIOS && urlMovies) {
            urlMovies = null
        }
        return (
            <Hero>
                {heroTrailer && (
                    <Hero.Video
                        playerRef={playerRef}
                        isMuted={isMuted}
                        setIsMuted={setIsMuted}
                        heroTrailer={'PEo2LzBi4_o'}
                        setHeroTrailer={setHeroTrailer}
                        urlTrailer={urlMovies}
                    />
                )}
                {banner && (
                    <React.Fragment>
                        {banner.backdrop_path && !heroTrailer &&
                        <Hero.Banner src={banner.backdrop_path} windowWidth={windowWidth}/>}
                        <Hero.Overlay fullOverlay={!heroTrailer}/>
                        {banner.overview && (
                            <Hero.Details className={heroTrailer ? 'no-desc' : ''}>
                                <Hero.Title className={!heroTrailer ? 'title-small' : ''}>
                                    {banner.name || banner.title || banner.original_name}
                                </Hero.Title>
                                {!heroTrailer &&
                                windowWidth > 600 &&
                                <Hero.Description>{truncate(banner.overview, 185)}</Hero.Description>}
                                <Hero.Button
                                    className="white-btn"
                                    onClick={() => {
                                        setHeroTrailer();
                                        banner.urlTV = dataURL || '';
                                        setPlaying(banner);
                                    }}
                                >
                                    <PlayArrowIcon/> <span>Xem ngay</span>
                                </Hero.Button>
                                <Hero.Button
                                    onClick={() => {
                                        setDetailsTrailer({
                                            id: banner.id,
                                            key: heroTrailer,
                                            start: playerRef && playerRef.current ? playerRef.current.getCurrentTime() : 0
                                        });
                                    }}
                                >
                                    <InfoOutlinedIcon/> <span>Chi tiết</span>
                                </Hero.Button>
                            </Hero.Details>
                        )}
                    </React.Fragment>
                )}
            </Hero>
        );
    } else if (category === 'packages') {
        return (<TvShow>
            <Form.Package>
                <Form.FormGroup method="POST">
                    <TvShow.ImageDetailPackage src={dataDetailChannel.image || ''}/>
                    <Form.TitlePackages>Gói xem phim và truyền hình cơ bản</Form.TitlePackages>
                    <TvShow.TextDescriptionPackage style={{color: "#faf6fa",fontSize: "1em"}} text={'Giá : 50.000vnd'}/>
                    <TvShow.TextDescriptionPackage style={{fontSize: "0.8em"}}
                                                   text={'Mời các bạn cùng trải nghiệm và thưởng thức những bộ phim phong phú về thể loại đến từ ' +
                                                   'nhiều quốc gia khác nhau một cách đơn giản và tiết kiệm với gói cước cơ bản 50.000 đồng một tháng.'}/>
                    <Form.ButtonPackage onClick={() => history.push(ROUTES.PAYMENT.path)} >
                        Mua Ngay
                    </Form.ButtonPackage>
                </Form.FormGroup>
            </Form.Package>
        </TvShow>)
    } else {
        if (isLoadJquery) {
            loadJquery()
        }
        return (
            <TvShow>
                <TvShow.DivSlider>
                    <TvShow.Slider setDataDetailChannel={setDataDetailChannel} handleClick={handleClick}
                                   data={dataTVShow}/>
                </TvShow.DivSlider>
                <TvShow.DivDetailChannel>
                    <TvShow.Detail>
                        <TvShow.TextDetailChannel text={dataDetailChannel.title || ''}/>
                        <TvShow.TextDescription text={truncate(dataDetailChannel.description, 200) || ''}/>
                        <TvShow.ImageDetail src={dataDetailChannel.image || ''} width={'100%'}/>
                        <TvShow.ButtonGroupChannelDetail>
                            {!dataDetailChannel.isBlocked && (<TvShow.Button
                                onClick={() => {
                                    const playdata = banner!!;
                                    playdata.isTVShow = true;
                                    playdata.urlTV = dataDetailChannel.url || '';
                                    setPlaying(playdata);
                                }}>
                                Xem Ngay
                            </TvShow.Button>)}
                            {dataDetailChannel.isBlocked && (<TvShow.Button
                                onClick={() => window.location.replace("/page404")}>
                                Xem Ngay
                            </TvShow.Button>)}
                            {banner && banner.overview && (
                                <TvShow.ButtonProgramProgress onClick={() => {
                                    setDetailsTrailer({
                                        id: banner.id,
                                        image: dataDetailChannel.logo || '',
                                        idChannel: dataDetailChannel.id|| '',
                                        start: playerRef && playerRef.current ? playerRef.current.getCurrentTime() : 0
                                    });
                                }}>
                                    Lịch chương trình
                                </TvShow.ButtonProgramProgress>
                            )}
                        </TvShow.ButtonGroupChannelDetail>
                    </TvShow.Detail>
                </TvShow.DivDetailChannel>
            </TvShow>
        )
    }
}

export default HeroContainer;
