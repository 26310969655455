import React from 'react';
import {Footer} from '../components';
import {INFO, INFO_FOOTER} from '../constants/constants';

function FooterContainer() {
    return (
        <Footer>
            <Footer.Title>Câu hỏi? Liên hệ chúng tôi.</Footer.Title>
            <Footer.Menu>
                <Footer.Info>
                    <Footer.TextInfo>{INFO_FOOTER.introduce}</Footer.TextInfo>
                    <Footer.TextInfo>{INFO_FOOTER.address_company}</Footer.TextInfo>
                    <Footer.TextInfo>Mã số doanh nghiệp: {INFO_FOOTER.business_code}.</Footer.TextInfo>
                    <Footer.TextInfo>Ngày cấp mã số doanh nghiệp: {INFO_FOOTER.date_business_code}.</Footer.TextInfo>
                    <Footer.TextInfo>Nơi cấp: {INFO_FOOTER.issued_by}.</Footer.TextInfo>
                </Footer.Info>
                <Footer.MenuItem>
                    <Footer.TextInfo>GIỚI THIỆU</Footer.TextInfo>
                    <Footer.TextInfo>
                        <Footer.Link href='/quy-che-su-dung-dich-vu'>Quy chế sử dụng Dịch vụ</Footer.Link>
                    </Footer.TextInfo>
                    <Footer.TextInfo>
                        <Footer.Link href='/chinh-sach-bao-mat'>Chính sách bảo mật</Footer.Link>
                    </Footer.TextInfo>
                    <Footer.TextInfo>
                        <Footer.Link href='/khuyen-mai'>Khuyến mãi</Footer.Link>
                    </Footer.TextInfo>
                </Footer.MenuItem>
                <Footer.MenuItem>
                    <Footer.TextInfo>HỖ TRỢ</Footer.TextInfo>
                    <Footer.TextInfo>
                        <Footer.Link href={`tel:${INFO.phone}`}>{INFO.phone} (24/7)</Footer.Link>
                    </Footer.TextInfo>
                    <Footer.TextInfo>
                        <Footer.Link href={`mailto:${INFO.email}`}>{INFO.email}</Footer.Link>
                    </Footer.TextInfo>
                    <Footer.TextInfo>
                        <Footer.Link href='/help'>https://galaxylinks.vn/help</Footer.Link>
                    </Footer.TextInfo>
                </Footer.MenuItem>
                <Footer.MenuItem>
                    <Footer.TextInfo>TẢI ỨNG DỤNG</Footer.TextInfo>
                    <Footer.TextInfo>
                        <Footer.Image src={'/images/icon/android-app.png'}/>
                        <Footer.Image src={'/images/icon/ios-app.png'}/>
                    </Footer.TextInfo>
                    <Footer.TextInfo>KẾT NỐI VỚI CHÚNG TÔI</Footer.TextInfo>
                    <Footer.TextInfo>
                    </Footer.TextInfo>
                </Footer.MenuItem>
            </Footer.Menu>
            <Footer.Text>Galaxy Links</Footer.Text>
        </Footer>
    );
}

export default FooterContainer;
