import React, { useState, useEffect } from 'react';
import { Player } from '../components';
import { LockBody } from '../components/loading/styles/loading';
import { ShowOverview } from '../constants/types';

type PlayerContainerType = {
	playing: ShowOverview;
	setPlaying: (userDetails?: ShowOverview) => void;
};
function PlayerContainer({ playing, setPlaying }: PlayerContainerType) {
	const [ isPaused, setIsPaused ] = useState();
	const category = playing.category || null;

	// const options = {
 //        headers: {
 //            Authorization: `Bearer YzE1ZDA4MTM3Njc1NzhlMw==`
 //        }
 //    }
 //    const currentURL = playing.urlTV || '';

 //    const [url, setUrl] = useState('')
 //    useEffect(() => {
 //        fetch(currentURL, options)
 //        .then(response => response.blob())
 //            .then(blob => {
 //            	if(blob) {
 //            		setUrl(URL.createObjectURL(blob))
 //            	}
 //        	});
 //    }, [currentURL])

	return (
		<Player>
			<Player.Video setUrl={playing.urlTV || ''} setIsPaused={setIsPaused} />
			<Player.Back onClick={() => setPlaying()} />
			<LockBody />
		</Player>
	);
}

export default PlayerContainer;
