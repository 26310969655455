import React, { useState, useEffect } from 'react';
import {Episode, Form, Profiles} from '../components';
import movieHttp from '../api/movie';
import { SECTIONS } from '../api/movieEndpoints';
import { IMAGE_BASE_URL, IMAGE_SIZES, STILL_PLACEHOLDER } from '../constants/constants';
import { Episode as EpisodeType, Season } from '../constants/types';
import {usePlayer} from "../context/PlayerContext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

type EpisodeContainerType = {
	openId: string;
	seasons: Array<Season>;
};
function EpisodeContainer({ openId, seasons }: EpisodeContainerType) {
	const {
		detailsTrailer: { detailsTrailer, setDetailsTrailer },
	} = usePlayer();
	const [ season, setSeason ] = useState<string>('1');
	const idChannel = detailsTrailer && detailsTrailer?.idChannel ? parseInt(detailsTrailer?.idChannel) - 1 : 0
	const [episodes, setEpisodes] = useState([
		[
			{
				id: 10,
				episode_number: 1,
				name: 'P1: Quyết tâm bám đảo',
				overview: 'P1: Quyết tâm bám đảo',
				time: '08:00',
			},
			{
				id: 10,
				episode_number: 2,
				name: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				overview: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				time: '08:40',
			},
			{
				id: 10,
				episode_number: 3,
				name: 'Tin nhanh : Tin nhanh 06/07/22',
				overview: 'Tin nhanh : Tin nhanh 06/07/22',
				time: '09:00',
			},
			{
				id: 10,
				episode_number: 4,
				name: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				overview: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				time: '09:15',
			},
			{
				id: 10,
				episode_number: 5,
				name: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				overview: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				time: '09:30',
			},
			{
				id: 10,
				episode_number: 6,
				name: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				overview: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				time: '09:40',
			},
			{
				id: 10,
				episode_number: 7,
				name: 'SITCOM : Sống chung với em chồng, tập 56/120',
				overview: 'SITCOM : Sống chung với em chồng, tập 56/120',
				time: '09:50',
			},
			{
				id: 10,
				episode_number: 8,
				name: 'An ninh thế giới : An ninh thế giới 06/07/22',
				overview: 'An ninh thế giới : An ninh thế giới 06/07/22',
				time: '10:00',
			},
			{
				id: 10,
				episode_number: 9,
				name: 'Phim tài liệu : Tiếp bước hào hùng',
				overview: 'Phim tài liệu : Tiếp bước hào hùng',
				time: '10:30',
			},
			{
				id: 10,
				episode_number: 10,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 11,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 12,
				name: '113 online : 113 online 06/07/22',
				overview: '113 online : 113 online 06/07/22',
				time: '11:30',
			},
			{
				id: 10,
				episode_number: 13,
				name: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				overview: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				time: '11:45',
			},
			{
				id: 10,
				episode_number: 14,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 15,
				name: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				overview: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				time: '12:00',
			}
		],
		[
			{
				id: 10,
				episode_number: 1,
				name: 'P1: Quyết tâm bám đảo',
				overview: 'P1: Quyết tâm bám đảo',
				time: '08:00',
			},
			{
				id: 10,
				episode_number: 2,
				name: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				overview: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				time: '08:40',
			},
			{
				id: 10,
				episode_number: 3,
				name: 'Tin nhanh : Tin nhanh 06/07/22',
				overview: 'Tin nhanh : Tin nhanh 06/07/22',
				time: '09:00',
			},
			{
				id: 10,
				episode_number: 4,
				name: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				overview: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				time: '09:15',
			},
			{
				id: 10,
				episode_number: 5,
				name: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				overview: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				time: '09:30',
			},
			{
				id: 10,
				episode_number: 6,
				name: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				overview: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				time: '09:40',
			},
			{
				id: 10,
				episode_number: 7,
				name: 'SITCOM : Sống chung với em chồng, tập 56/120',
				overview: 'SITCOM : Sống chung với em chồng, tập 56/120',
				time: '09:50',
			},
			{
				id: 10,
				episode_number: 8,
				name: 'An ninh thế giới : An ninh thế giới 06/07/22',
				overview: 'An ninh thế giới : An ninh thế giới 06/07/22',
				time: '10:00',
			},
			{
				id: 10,
				episode_number: 9,
				name: 'Phim tài liệu : Tiếp bước hào hùng',
				overview: 'Phim tài liệu : Tiếp bước hào hùng',
				time: '10:30',
			},
			{
				id: 10,
				episode_number: 10,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 11,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 12,
				name: '113 online : 113 online 06/07/22',
				overview: '113 online : 113 online 06/07/22',
				time: '11:30',
			},
			{
				id: 10,
				episode_number: 13,
				name: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				overview: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				time: '11:45',
			},
			{
				id: 10,
				episode_number: 14,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 15,
				name: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				overview: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				time: '12:00',
			}
		],
		[
			{
				id: 10,
				episode_number: 1,
				name: 'P1: Quyết tâm bám đảo',
				overview: 'P1: Quyết tâm bám đảo',
				time: '08:00',
			},
			{
				id: 10,
				episode_number: 2,
				name: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				overview: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				time: '08:40',
			},
			{
				id: 10,
				episode_number: 3,
				name: 'Tin nhanh : Tin nhanh 06/07/22',
				overview: 'Tin nhanh : Tin nhanh 06/07/22',
				time: '09:00',
			},
			{
				id: 10,
				episode_number: 4,
				name: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				overview: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				time: '09:15',
			},
			{
				id: 10,
				episode_number: 5,
				name: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				overview: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				time: '09:30',
			},
			{
				id: 10,
				episode_number: 6,
				name: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				overview: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				time: '09:40',
			},
			{
				id: 10,
				episode_number: 7,
				name: 'SITCOM : Sống chung với em chồng, tập 56/120',
				overview: 'SITCOM : Sống chung với em chồng, tập 56/120',
				time: '09:50',
			},
			{
				id: 10,
				episode_number: 8,
				name: 'An ninh thế giới : An ninh thế giới 06/07/22',
				overview: 'An ninh thế giới : An ninh thế giới 06/07/22',
				time: '10:00',
			},
			{
				id: 10,
				episode_number: 9,
				name: 'Phim tài liệu : Tiếp bước hào hùng',
				overview: 'Phim tài liệu : Tiếp bước hào hùng',
				time: '10:30',
			},
			{
				id: 10,
				episode_number: 10,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 11,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 12,
				name: '113 online : 113 online 06/07/22',
				overview: '113 online : 113 online 06/07/22',
				time: '11:30',
			},
			{
				id: 10,
				episode_number: 13,
				name: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				overview: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				time: '11:45',
			},
			{
				id: 10,
				episode_number: 14,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 15,
				name: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				overview: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				time: '12:00',
			}
		],
		[
			{
				id: 10,
				episode_number: 1,
				name: 'P1: Quyết tâm bám đảo',
				overview: 'P1: Quyết tâm bám đảo',
				time: '08:00',
			},
			{
				id: 10,
				episode_number: 2,
				name: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				overview: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				time: '08:40',
			},
			{
				id: 10,
				episode_number: 3,
				name: 'Tin nhanh : Tin nhanh 06/07/22',
				overview: 'Tin nhanh : Tin nhanh 06/07/22',
				time: '09:00',
			},
			{
				id: 10,
				episode_number: 4,
				name: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				overview: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				time: '09:15',
			},
			{
				id: 10,
				episode_number: 5,
				name: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				overview: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				time: '09:30',
			},
			{
				id: 10,
				episode_number: 6,
				name: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				overview: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				time: '09:40',
			},
			{
				id: 10,
				episode_number: 7,
				name: 'SITCOM : Sống chung với em chồng, tập 56/120',
				overview: 'SITCOM : Sống chung với em chồng, tập 56/120',
				time: '09:50',
			},
			{
				id: 10,
				episode_number: 8,
				name: 'An ninh thế giới : An ninh thế giới 06/07/22',
				overview: 'An ninh thế giới : An ninh thế giới 06/07/22',
				time: '10:00',
			},
			{
				id: 10,
				episode_number: 9,
				name: 'Phim tài liệu : Tiếp bước hào hùng',
				overview: 'Phim tài liệu : Tiếp bước hào hùng',
				time: '10:30',
			},
			{
				id: 10,
				episode_number: 10,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 11,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 12,
				name: '113 online : 113 online 06/07/22',
				overview: '113 online : 113 online 06/07/22',
				time: '11:30',
			},
			{
				id: 10,
				episode_number: 13,
				name: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				overview: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				time: '11:45',
			},
			{
				id: 10,
				episode_number: 14,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 15,
				name: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				overview: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				time: '12:00',
			}
		],
		[
			{
				id: 10,
				episode_number: 1,
				name: 'P1: Quyết tâm bám đảo',
				overview: 'P1: Quyết tâm bám đảo',
				time: '08:00',
			},
			{
				id: 10,
				episode_number: 2,
				name: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				overview: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				time: '08:40',
			},
			{
				id: 10,
				episode_number: 3,
				name: 'Tin nhanh : Tin nhanh 06/07/22',
				overview: 'Tin nhanh : Tin nhanh 06/07/22',
				time: '09:00',
			},
			{
				id: 10,
				episode_number: 4,
				name: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				overview: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				time: '09:15',
			},
			{
				id: 10,
				episode_number: 5,
				name: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				overview: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				time: '09:30',
			},
			{
				id: 10,
				episode_number: 6,
				name: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				overview: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				time: '09:40',
			},
			{
				id: 10,
				episode_number: 7,
				name: 'SITCOM : Sống chung với em chồng, tập 56/120',
				overview: 'SITCOM : Sống chung với em chồng, tập 56/120',
				time: '09:50',
			},
			{
				id: 10,
				episode_number: 8,
				name: 'An ninh thế giới : An ninh thế giới 06/07/22',
				overview: 'An ninh thế giới : An ninh thế giới 06/07/22',
				time: '10:00',
			},
			{
				id: 10,
				episode_number: 9,
				name: 'Phim tài liệu : Tiếp bước hào hùng',
				overview: 'Phim tài liệu : Tiếp bước hào hùng',
				time: '10:30',
			},
			{
				id: 10,
				episode_number: 10,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 11,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 12,
				name: '113 online : 113 online 06/07/22',
				overview: '113 online : 113 online 06/07/22',
				time: '11:30',
			},
			{
				id: 10,
				episode_number: 13,
				name: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				overview: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				time: '11:45',
			},
			{
				id: 10,
				episode_number: 14,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 15,
				name: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				overview: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				time: '12:00',
			}
		],
		[
			{
				id: 10,
				episode_number: 1,
				name: 'P1: Quyết tâm bám đảo',
				overview: 'P1: Quyết tâm bám đảo',
				time: '08:00',
			},
			{
				id: 10,
				episode_number: 2,
				name: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				overview: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				time: '08:40',
			},
			{
				id: 10,
				episode_number: 3,
				name: 'Tin nhanh : Tin nhanh 06/07/22',
				overview: 'Tin nhanh : Tin nhanh 06/07/22',
				time: '09:00',
			},
			{
				id: 10,
				episode_number: 4,
				name: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				overview: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				time: '09:15',
			},
			{
				id: 10,
				episode_number: 5,
				name: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				overview: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				time: '09:30',
			},
			{
				id: 10,
				episode_number: 6,
				name: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				overview: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				time: '09:40',
			},
			{
				id: 10,
				episode_number: 7,
				name: 'SITCOM : Sống chung với em chồng, tập 56/120',
				overview: 'SITCOM : Sống chung với em chồng, tập 56/120',
				time: '09:50',
			},
			{
				id: 10,
				episode_number: 8,
				name: 'An ninh thế giới : An ninh thế giới 06/07/22',
				overview: 'An ninh thế giới : An ninh thế giới 06/07/22',
				time: '10:00',
			},
			{
				id: 10,
				episode_number: 9,
				name: 'Phim tài liệu : Tiếp bước hào hùng',
				overview: 'Phim tài liệu : Tiếp bước hào hùng',
				time: '10:30',
			},
			{
				id: 10,
				episode_number: 10,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 11,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 12,
				name: '113 online : 113 online 06/07/22',
				overview: '113 online : 113 online 06/07/22',
				time: '11:30',
			},
			{
				id: 10,
				episode_number: 13,
				name: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				overview: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				time: '11:45',
			},
			{
				id: 10,
				episode_number: 14,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 15,
				name: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				overview: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				time: '12:00',
			}
		],
		[
			{
				id: 10,
				episode_number: 1,
				name: 'P1: Quyết tâm bám đảo',
				overview: 'P1: Quyết tâm bám đảo',
				time: '08:00',
			},
			{
				id: 10,
				episode_number: 2,
				name: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				overview: 'Kỹ năng sống : Kỹ năng sinh sống nơi đảo xa, P2: Quyết tâm bám đảo',
				time: '08:40',
			},
			{
				id: 10,
				episode_number: 3,
				name: 'Tin nhanh : Tin nhanh 06/07/22',
				overview: 'Tin nhanh : Tin nhanh 06/07/22',
				time: '09:00',
			},
			{
				id: 10,
				episode_number: 4,
				name: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				overview: 'Xây dựng Đảng : Xứng đáng là thanh bảo kiếm, lá chắn bảo vệ nhân dân, bảo vệ Đảng, bảo vệ chế độ,',
				time: '09:15',
			},
			{
				id: 10,
				episode_number: 5,
				name: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				overview: 'Vì nhân dân phục vụ : Bắc Ruộng nhiều đổi thay',
				time: '09:30',
			},
			{
				id: 10,
				episode_number: 6,
				name: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				overview: 'NHÂN VẬT - SỰ KIỆN : Người kể chuyện giới tuyến',
				time: '09:40',
			},
			{
				id: 10,
				episode_number: 7,
				name: 'SITCOM : Sống chung với em chồng, tập 56/120',
				overview: 'SITCOM : Sống chung với em chồng, tập 56/120',
				time: '09:50',
			},
			{
				id: 10,
				episode_number: 8,
				name: 'An ninh thế giới : An ninh thế giới 06/07/22',
				overview: 'An ninh thế giới : An ninh thế giới 06/07/22',
				time: '10:00',
			},
			{
				id: 10,
				episode_number: 9,
				name: 'Phim tài liệu : Tiếp bước hào hùng',
				overview: 'Phim tài liệu : Tiếp bước hào hùng',
				time: '10:30',
			},
			{
				id: 10,
				episode_number: 10,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 11,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 12,
				name: '113 online : 113 online 06/07/22',
				overview: '113 online : 113 online 06/07/22',
				time: '11:30',
			},
			{
				id: 10,
				episode_number: 13,
				name: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				overview: 'Nhận diện tội phạm : Nhận diện tội phạm 06/07/22',
				time: '11:45',
			},
			{
				id: 10,
				episode_number: 14,
				name: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				overview: 'An ninh toàn cảnh : An ninh toàn cảnh 06/07/22',
				time: '11:00',
			},
			{
				id: 10,
				episode_number: 15,
				name: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				overview: 'Phim truyện 12h00 : Trọng lực cầu vồng, tập 24/40',
				time: '12:00',
			}
		],
		]
	);
	const [ showAll, setShowAll ] = useState(false);

	useEffect(
		() => {
/*			movieHttp
				.get(SECTIONS.series.helpers.fetchTVSeason.replace('{{tv_id}}', openId).replace('{{season_number}}', season))
				.then((response) => setEpisodes(response.data.episodes))
				.catch((e) => console.log(e));*/
		},
		[ season, openId ]
	);

	const truncate = (string: string, length: number) => {
		return string.length > length ? string.substr(0, length - 1) + '...' : string;
	};
	return (
		<Episode.Container>
			<Episode.Header>
				<Episode.Title>Lịch phát sóng</Episode.Title>
{/*				<Episode.Dropdown seasons={seasons} setSeason={setSeason} season={season} />*/}
			</Episode.Header>
			{episodes && (
				<Episode.List>
					{episodes[idChannel].map(
						({id, episode_number, name, overview, time}, i) =>
							(showAll || (!showAll && i < 15)) && (
								<Episode key={id}>
									<Episode.Panel className="episode-number">{episode_number}</Episode.Panel>
									<Episode.Panel className="episode-image">
										<img
											src={`/images/tvShow/${detailsTrailer?.image}`}
											alt={`Episode ${episode_number} Still`}
										/>
									</Episode.Panel>
									<Episode.Panel className="episode-details">
										<Episode.ListItemTitle>{time}</Episode.ListItemTitle>
										<Episode.Overview>{name}</Episode.Overview>
									</Episode.Panel>
								</Episode>
							)
					)}
					{episodes.length > 10 && (
						<Episode.ShowMore onClick={() => setShowAll((showAll) => !showAll)} showAll={showAll} />
					)}
					{episodes.length === 0 && <p className="no-episodes">No episodes yet.</p>}
				</Episode.List>
			)}
		</Episode.Container>
	);
}

export default EpisodeContainer;
