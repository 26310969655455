import React, { useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { FooterContainer, HeaderContainer } from '../containers';
import { Form } from '../components';
import {INFO} from "../constants/constants";

function Promotion() {
	const [ isHeaderShown, setHeaderShown ] = useState(false);

	const handleOnScroll = (scrollTop: number) => {
		if (scrollTop > 100 && !isHeaderShown) {
			setHeaderShown(true);
		} else if (scrollTop <= 100 && isHeaderShown) {
			setHeaderShown(false);
		}
	};
	return (
		<Scrollbar noDefaultStyles className="main-scrollbar" onScroll={({ scrollTop }: any) => handleOnScroll(scrollTop)}>
			<HeaderContainer isHeaderShown={isHeaderShown} />
			<Form.Cus>
				<Form.Title>Khách hàng cần chúng tôi hỗ trợ?</Form.Title>
				<Form.Text style={{color: "#ffffff"}}>
					Hỗ trợ giải đáp các vấn đề trong quá trình sử dụng Dịch vụ Galaxy Links.
				</Form.Text>
				<Form.Text style={{color: "#ffffff"}}>
					Email: {INFO.email}
				</Form.Text>
				<Form.Text style={{color: "#ffffff"}}>
					Hotline: {INFO.phone}
				</Form.Text>
			</Form.Cus>
			<FooterContainer />
		</Scrollbar>
	);
}

export default Promotion;
