import React, { useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { Form } from '../components';
import { ROUTES } from '../constants/routes';
import { FooterContainer, HeaderContainer } from '../containers';
import mainHttp from '../api/mainEndpoints';
import { useUser } from '../context/UserContext';

function Forgot() {
	const [ email, setEmail ] = useState('');
	const [ errorMsg, setErrorMsg ] = useState('');
	const [ isTouched, setIsTouched ] = useState({ email: false, password: false });
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isHeaderShown, setHeaderShown ] = useState(false);
	const emailInvalid = isTouched.email && email === '';
	const canProceed = email && !emailInvalid;

	const handleSignin = (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		if (canProceed) {
			mainHttp
				.forgot({ email })
				.then(({ success, message }: any) => {
					console.log(success, message)
					setErrorMsg(message);
					setIsLoading(false);
					setEmail('')
				})
				.catch(({ response }) => {
					setIsLoading(false);
					setErrorMsg(response.data.message);
				});
		}
	};

	const handleOnScroll = (scrollTop: number) => {
		if (scrollTop > 100 && !isHeaderShown) {
			setHeaderShown(true);
		} else if (scrollTop <= 100 && isHeaderShown) {
			setHeaderShown(false);
		}
	};

	return (
		<Scrollbar noDefaultStyles className="main-scrollbar"
				   onScroll={({scrollTop}: any) => handleOnScroll(scrollTop)}>
			<HeaderContainer logoOnly isHeaderShown={isHeaderShown}/>
			<Form>
				<Form.Title>Quên mật khẩu</Form.Title>
				<Form.FormGroup onSubmit={handleSignin} method="POST">
					{errorMsg && <Form.Error className="boxed">{errorMsg}</Form.Error>}
					<Form.Input
						placeholder="Vui lòng nhập Email"
						value={email}
						onChange={({target}: any) => {
							if (!isTouched.email)
								setIsTouched((prevIsTouched) => {
									return {...prevIsTouched, email: true};
								});
							setEmail(target.value);
						}}
						className={emailInvalid ? 'has-error' : ''}
					/>
					<Form.Button disabled={!canProceed || isLoading} type="submit">
						{isLoading ? (
							<React.Fragment>
								<Form.Spinner/>Đang xử lý ...
							</React.Fragment>
						) : (
							'Lấy lại mật khẩu'
						)}
					</Form.Button>
				</Form.FormGroup>
				<Form.TextSmall style={{color: "#ffffff"}}>Trang này được bảo vệ bởi reCAPTCHA của Google để đảm bảo bạn
					không phải là bot.</Form.TextSmall>
			</Form>
			<FooterContainer/>
		</Scrollbar>
	);
}

export default Forgot;
