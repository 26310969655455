export const GALAXY_LINKS_SECTIONS = ["Thriller","Politist"]
export const GALAXY_LINKS_CMS = process.env.GALAXY_LINKS_CMS || 'https://www.galaxylinks.vn/images'

export const IMAGE_BASE_URL = '/images/';
export const IMAGE_SIZES = {
	backdrop: {
		small: 'w300',
		xmedium: 'w780',
		medium: 'w1280',
		large: 'original'
	},
	poster: {
		small: 'w154',
		medium: 'w185',
		large: 'w342'
	},
	still: {
		small: 'w92'
	}
};
export const BACKDROP_PLACEHOLDER = 'https://plchldr.co/i/300x169?bg=333333&text=GALAXYLINKS';
export const POSTER_PLACEHOLDER = 'https://plchldr.co/i/342x513?bg=333333&text=GALAXYLINKS';
export const STILL_PLACEHOLDER = 'https://plchldr.co/i/185x104?bg=333333&text=GALAXYLINKS';

export const HEADER_LINKS = [
	{
		title: 'Truyền hình',
		value: 'series'
	},
	{
		title: 'Phim',
		value: 'movies'
	},
	{
		title: 'Gói thuê bao',
		value: 'packages'
	}
];

export const FAQS = [
	{
		id: 1,
		header: 'Galaxy Links là gì?',
		body:
			"Galaxy Links là dịch vụ phát trực tuyến cung cấp nhiều chương trình truyền hình, phim ảnh, phim hoạt hình, phim tài liệu từng đoạt giải thưởng và hơn thế nữa trên hàng nghìn thiết bị có kết nối internet.\n\nBạn có thể xem bao nhiêu tùy thích, bất cứ khi nào bạn muốn mà không cần một quảng cáo nào - tất cả chỉ với một mức giá thấp hàng tháng. Luôn có điều gì đó mới để khám phá và các chương trình truyền hình và phim mới được thêm vào mỗi tuần!"
	},
	{
		id: 2,
		header: 'Galaxy Links giá bao nhiêu?',
		body:
			'Xem Galaxy Links trên điện thoại thông minh, máy tính bảng, Smart TV, máy tính xách tay hoặc thiết bị phát trực tuyến của bạn, tất cả với một khoản phí cố định hàng tháng.'
	},
	{
		id: 3,
		header: 'Tôi có thể xem ở đâu?',
		body:
			"Xem mọi lúc mọi nơi trên số lượng thiết bị không giới hạn. Đăng nhập bằng tài khoản Galaxy Links của bạn để xem ngay lập tức trên web tại roseflix.com từ máy tính cá nhân của bạn hoặc trên bất kỳ thiết bị được kết nối internet nào cung cấp ứng dụng Galaxy Links, bao gồm TV thông minh, điện thoại thông minh, máy tính bảng, trình phát đa phương tiện và bảng điều khiển trò chơi.\n\nBạn cũng có thể tải xuống các chương trình yêu thích của mình bằng ứng dụng iOS, Android hoặc Windows 10. Sử dụng nội dung tải xuống để xem khi bạn đang di chuyển và không có kết nối internet. Mang Galaxy Links theo bạn ở bất cứ đâu."
	},
	{
		id: 4,
		header: 'Làm cách nào để hủy?',
		body:
			'Galaxy Links rất linh hoạt. Không có hợp đồng khó chịu và không có cam kết. Bạn có thể dễ dàng hủy bỏ tài khoản của mình trực tuyến trong hai cú nhấp chuột. Không có phí hủy - bắt đầu hoặc dừng tài khoản của bạn bất cứ lúc nào.'
	},
	{
		id: 5,
		header: 'Tôi có thể xem gì trên Galaxy Links?',
		body:
			'Galaxy Links có một thư viện phong phú bao gồm phim truyện, phim tài liệu, chương trình truyền hình, anime, các bản gốc Galaxy Links từng đoạt giải thưởng và hơn thế nữa. Xem bao nhiêu tùy thích, bất cứ lúc nào bạn muốn.'
	}
];

export const FEATURES = [
	{
		id: 1,
		title: 'Thưởng thức trên TV của bạn.',
		description: 'Xem trên smart TVs, PlayStation, Xbox, Chromecast, Apple TV, Blu-ray players và hơn thế nữa.',
		video: '',
		image: '/images/misc/home-tv.jpg',
		alt: 'Galaxy Links on TV'
	},
	{
		id: 2,
		title: 'Tải xuống các chương trình của bạn để xem ngoại tuyến.',
		description: 'Lưu mục yêu thích của bạn một cách dễ dàng và luôn có nội dung để xem.',
		video: '',
		image: '/images/misc/home-mobile.jpg',
		alt: 'Galaxy Links on mobile',
		direction: 'row-reverse'
	},
	{
		id: 3,
		title: 'Xem khắp mọi nơi.',
		description: 'Phát trực tuyến các bộ phim và chương trình truyền hình không giới hạn trên điện thoại, máy tính bảng, máy tính xách tay và TV của bạn mà không phải trả thêm tiền.',
		image: '/images/misc/home-imac.jpg',
		alt: 'Galaxy Links on different devices'
	}
];

export const FOOTER_LINKS = [
	{ id: 1, text: 'FAQ', url: 'https://help.netflix.com/support/412' },
	{ id: 2, text: 'Help Center', url: 'https://help.netflix.com/' },
	{ id: 3, text: 'Account', url: 'https://www.netflix.com/youraccount' },
	{ id: 4, text: 'Media Center', url: 'https://media.netflix.com/' },
	{ id: 5, text: 'Investor Relations', url: 'http://ir.netflix.com/' },
	{ id: 6, text: 'Jobs', url: 'https://jobs.netflix.com/jobs' },
	{ id: 7, text: 'Redeem Gift Cards', url: 'https://www.netflix.com/redeem' },
	{ id: 8, text: 'Buy Gift Cards', url: 'https://www.netflix.com/gift-cards' },
	{ id: 9, text: 'Ways to Watch', url: 'https://www.netflix.com/watch' },
	{ id: 10, text: 'Terms of Use', url: 'https://help.netflix.com/legal/termsofuse' },
	{ id: 11, text: 'Privacy', url: 'https://help.netflix.com/legal/privacy' },
	{ id: 12, text: 'Cookie Preferences', url: 'https://www.netflix.com/ph/#' },
	{ id: 13, text: 'Corporate Information', url: 'https://help.netflix.com/legal/corpinfo' },
	{ id: 14, text: 'Contact Us', url: 'https://help.netflix.com/contactus' },
	{ id: 15, text: 'Speed Test', url: 'https://fast.com/' },
	{ id: 16, text: 'Legal Notices', url: 'https://help.netflix.com/legal/notices' },
	{ id: 17, text: 'Galaxy Links Originals', url: 'https://www.netflix.com/ph/browse/genre/839338' }
];

export const INFO_FOOTER = {
	introduce: 'CÔNG TY TNHH MỘT THÀNH VIÊN GALAXY LINKS' +
		'(GALAXY LINKS ONE-MEMBER COMPANY LIMITED)',
	address_company: 'Địa chỉ: Số 126 đường Nguyễn Thị Minh Khai, Phường Võ Thị Sáu, Quận 3, Thành phố Hồ Chí Minh',
	business_code: '0316379088',
	date_business_code : '10/07/2020',
	issued_by : 'Sở kế hoạch và đầu tư'
};
export const INFO = {
	email:'support@galaxylinks.vn',
	phone:'19002224'
}
