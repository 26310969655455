import React, {useState} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import {FooterContainer, HeaderContainer} from '../../containers';
import {Footer, Form} from '../../components';
import {INFO} from "../../constants/constants";

function Payment() {
    const [isHeaderShown, setHeaderShown] = useState(false);

    const handleOnScroll = (scrollTop: number) => {
        if (scrollTop > 100 && !isHeaderShown) {
            setHeaderShown(true);
        } else if (scrollTop <= 100 && isHeaderShown) {
            setHeaderShown(false);
        }
    };
    return (
        <Scrollbar noDefaultStyles className="main-scrollbar"
                   onScroll={({scrollTop}: any) => handleOnScroll(scrollTop)}>
            <HeaderContainer isHeaderShown={isHeaderShown}/>
            <Form.Payment>
                <Form.Title>Kính đề nghị quý khách thanh toán theo 02 bước sau:</Form.Title>
                <Form.Text style={{color: "#ffffff"}}>
                    Bước 1: Thực hiện chuyển khoản theo thông tin bên dưới:
                    <Form.Text style={{color: "#ffffff", paddingLeft: "5%", width: "100%"}}>
                        • Đơn vị thụ hưởng: CÔNG TY TNHH MTV GALAXY LINKS<br/>
                        • Số tài khoản: 111002889094 tại Ngân hàng TMCP Công Thương Việt Nam (Vietinbank) - Chi Nhánh 2, TP.HCM.<br/>
                        • Nội dung chuyển khoản: Tên tài khoản, số điện thoại và cước phí tháng<br/>
                        (Ví dụ: Nguyen Van A, 09xxx…; TT thang 8/2022).
                    </Form.Text>
                </Form.Text>
                <Form.Text style={{color: "#ffffff"}}>
                    Bước 2: Gửi email tới GalaxyLinks (<Footer.Link  style={{color: "#F58020"}} href={`mailto:${INFO.email}`}>{INFO.email}</Footer.Link>) để xác nhận chuyển khoản thành công (vui lòng đính kèm hình ảnh giúp chúng tôi).
                    Lưu ý: vui lòng đính kèm hình ảnh giúp chúng tôi.
                    <br/><br/>
                    GalaxyLinks sẽ xử lý yêu cầu của bạn trong thời gian nhanh nhất.
                    Cảm ơn bạn đã lựa chọn dịch vụ của chúng tôi.<br/>
                    GalaxyLinks xin tiếp nhận mọi thắc mắc và góp ý để hoàn thiện và nâng cấp dịch vụ tốt hơn.<br/>
                    Vui lòng liên hệ: <Footer.Link style={{color: "#F58020"}} href={`mailto:${INFO.email}`}>{INFO.email}</Footer.Link>
                </Form.Text>
                <Form.ButtonBackHome>
                    Quay lại trang chủ
                </Form.ButtonBackHome>
            </Form.Payment>
            <FooterContainer/>
        </Scrollbar>
    );
}

export default Payment;
