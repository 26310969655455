import React from 'react';
import { ComponentProp } from '../../constants/types';
import {
	Container,
	Inner,
	Title,
	FormGroup,
	Input,
	Button,
	Text,
	TextSmall,
	Link,
	Error,
	Spinner,
	InnerCus,
	TextCus,
	ContainerCus,
	InputCus,
	TextCheckBoxStyle,
	ContainerPackages,
	InnerPackages,
	TitleProfile,
	TitlePackages,
	ButtonPackage, ContainerPayment, InnerPayment
} from './styles/form';

function Form({ children, ...restProps }: ComponentProp) {
	return (
		<Container {...restProps}>
			<Inner>{children}</Inner>
		</Container>
	);
}

Form.Title = function FormTitle({ children, ...restProps }: ComponentProp) {
	return <Title {...restProps}>{children}</Title>;
};

Form.TitlePackages = function FormTitlePackages({ children, ...restProps }: ComponentProp) {
	return <TitlePackages {...restProps}>{children}</TitlePackages>;
};

Form.FormGroup = function FormFormGroup({ children, ...restProps }: ComponentProp) {
	return <FormGroup {...restProps}>{children}</FormGroup>;
};

Form.Input = function FormInput({ ...restProps }) {
	return <Input {...restProps} />;
};
Form.InputCheckBox = function InputCheckBox({ ...restProps }) {
	return <InputCus {...restProps} />;
};
Form.Button = function FormButton({ children, ...restProps }: ComponentProp) {
	return <Button {...restProps}>{children}</Button>;
};

Form.ButtonBackHome = function FormButtonBackHome({children, ...restProps}: ComponentProp) {
	return <Button onClick={(e) => window.location.replace("/")} {...restProps}>{children}</Button>;
};

Form.ButtonPackage = function FormButtonPackage({ children, ...restProps }: ComponentProp) {
	return <ButtonPackage {...restProps}>{children}</ButtonPackage>;
};

Form.Text = function FormText({ children, ...restProps }: ComponentProp) {
	return <Text {...restProps}>{children}</Text>;
};

Form.TitleProfile = function FormTitleProfile({ children, ...restProps }: ComponentProp) {
	return <TitleProfile {...restProps}>{children}</TitleProfile>;
};

Form.TextCheckBox = function TextCheckBox({ children, ...restProps }: ComponentProp) {
	return <TextCheckBoxStyle {...restProps}>{children}</TextCheckBoxStyle>;
};

Form.TextSmall = function FormTextSmall({ children, ...restProps }: ComponentProp) {
	return <TextSmall {...restProps}>{children}</TextSmall>;
};

Form.Link = function FormLink({ children, to, ...restProps }: ComponentProp) {
	return (
		<Link to={to} {...restProps}>
			{children}
		</Link>
	);
};

Form.Error = function FormError({ children, ...restProps }: ComponentProp) {
	return <Error {...restProps}>{children}</Error>;
};

Form.Spinner = function FormSpinner({ ...restProps }: ComponentProp) {
	return <Spinner {...restProps} />;
};

Form.Cus = function FormCus({ children, ...restProps }: ComponentProp) {
	return (
		<ContainerCus {...restProps}>
			<InnerCus>{children}</InnerCus>
		</ContainerCus>
	);
}
Form.TextCus = function FormTextCus({ children, ...restProps }: ComponentProp) {
	return <TextCus {...restProps}>{children}</TextCus>;
};
Form.Package = function FormPackage({ children, ...restProps }: ComponentProp) {
	return (
		<ContainerPackages {...restProps}>
			<InnerPackages>{children}</InnerPackages>
		</ContainerPackages>
	);
}

Form.Payment = function FormPayment({ children, ...restProps }: ComponentProp) {
	return (
		<ContainerPayment {...restProps}>
			<InnerPayment>{children}</InnerPayment>
		</ContainerPayment>
	);
}


export default Form;
